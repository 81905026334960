import React from 'react';
import { Wrapper } from '../components';

const TicketsPage = () => (
  <Wrapper title="2022 Tickets">
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <p>2022 Tickets Have Officially Sold Out!</p>
        </div>
      </section>
    </div>
  </Wrapper>
);
export default TicketsPage;
